/* eslint-disable */
import notificationTasks from './notificationTasks/ru';

const ru = {

  // Validation
  "Confirmation code": "Код подтверждения",
  "Approve": "Подтвердить",
  "Send code": "Отправить код",
  "Wrong code format": "Неверный формат кода",
  "Send new code": "Отправить новый код",
  "Enter a number or Email": "Укажите номер или Email",
  "Validation error": "Ошибка валидации",
  "Error during validation": "Ошибка в процессе валидации",
  "Error requesting code": "Ошибка при запросе кода подтверждения",
  "This is a required field": "Это обязательное поле",
  "Password cannot be less than 8 characters": "Не менее 8 знаков, обязательно содержать прописные и строчные латинские буквы, одну цифру и спецсимвол",
  "At least 6 characters, only numbers and Latin letters": "Не менее 6 символов, только цифры и латинские буквы",
  "Wrong email or phone number": "Некорректно введен email или телефон",
  "Confirm password mismatch": "Пароли не совпадают",
  "Password restored": "Пароль восстановлен",
  "Password success changed":"Пароль успешно восстановлен. Вы можете использовать свой новый пароль для входа",

  // Notification Tasks
  notificationTasks,

  // Common
  "To generate the report, you must specify the email address": "Для формирования отчета необходимо указать email адрес",
  "set email": "задать email",
  "Repeat password": "Повторите пароль",
  "New password": "Новый пароль",
  "Confirmation code sent to the email/phone number": "Если указанный телефон/email зарегистрирован в системе, на него отправлено сообщение с кодом подтверждения",
  "Reset password": "Восстановление пароля",
  "Password reset": "Пароль восстановлен",
  "Next": "Далее",
  "To signin": "К авторизации",
  "Phone / Email": "Телефон / Email",
  "OK": "ОК",
  "Open": "Выбрать файлы",
  "Import data from USB": "Импортировать данные с USB",
  "Wrong file format": "Формат файла не поддерживается",
  "import completed": "Данные импортированы",
  "The geozone should not self-crossing": "Границы геозоны не должны взаимо пересекаться",
  more: "Подробнее",
  sign_out: "Выход",
  helpers: "Подсказки",
  desktop: "Рабочий стол",
  "map (new)": "Карта (новая)",
  myMachines: "Машины",
  tracking: "Слежение",
  watch: "Анализ",
  notifications: "Тех. контроль",
  Notifications: "Уведомления",
  settings: "Управление",
  sortByQuantity: "Сортировать по количеству",
  map: "Карта",
  activity: "Активность",
  removed: "Убрано/обработано",
  name_of_the_farm: "Название хозяйства",
  machines_total: "Машин всего",
  active: "Активные",
  warning: "Критические",
  farming_name: "Название хозяйства",
  machines_quantity: "Всего машин",
  activities: "Активность",
  fuel: "Топливо",
  conservants: "Система внесения консервантов",
  machin: "машин",
  machine: "машина",
  machines: "машины",
  amountOfHours: "Общее количество часов",
  inclusion_DVS: "Запуск ДВС",
  last_data: "Последние данные",
  speed_at_now: "Текущая скорость",
  speed: "Скорость",
  average_speed_move_in_harvest: "Ср. скорость работы",
  expense_of_fuel: "Расход топлива",
  area_cleaned: "Убранная/обраб. площадь",
  performance: "Производ.",
  watching: "Слежение",
  status: "Статус",
  type_of_machine: "Модель техники",
  farming: "Хозяйство",
  today: "Сегодня",
  day: "День",
  month: "Месяц",
  year: "Год",
  week: "Неделя",
  date: "Дата",
  select_a_date: "Выбрать даты",
  track: "Трек",
  accept: "Принять",
  range_of_date: "Период времени",
  drain: "Сливы",
  refuel: "Заправки",
  indications_of_work: "Показатели работы",
  consolidated_statement_about_machines: "Сводный отчёт по машинам",
  events_a_o: "События по с/о",
  export: "Экспорт",
  model: "Модель",
  time: "Время",
  status_of_message: "Статус сообщения",
  category: "Категория",
  found: "Найдено",
  selecting_date: "Выбрать дату",
  analysis: "Анализ",
  use_of_time: "Использование времени",
  report_about_machine: "Отчёт по машине",
  fuel_report: "Отчёт по топливу",
  harvest: "Рабочий режим",
  driving_mode: "Дорожный режим",
  work_engine_in_no_movement: "Простой с вкл. двигателем",
  liters: "литров",
  farmings: "Хозяйства",
  geozones: "Геозоны",
  search: "Поиск",
  search_for: "Поиск хозяйств и машин",
  search_for_geozone: "Поиск геозоны",
  params_of_report: "Параметры отчёта",
  format_of_file: "Формат файла",
  type: "Тип",
  events: "События",
  event: "Событие",
  count: "Количество",
  duration: "Длительность",
  date_of_start: "Дата начала",
  time_of_start: "Время начала",
  time_of_end: "Время конца",
  cancelChanges: "Изменения отменены",
  saveProfileWait: "Информация сохраняется",
  saveProfileDone: "Информация сохранена",
  saveProfileError: "Возникла ошибка при сохранении",
  savePhotoWait: "Фото сохраняется",
  savePhotoDone: "Фото сохранено",
  deleteProfileWait: "Информация удаляется",
  deleteProfileDone: "Информация удалена",
  pleaseWait: "Пожалуйста подождите",
  deletePhoto: "Фото удалено",
  photoTooLarge: "Слишком большой размер фото",
  maxPhotoSize: "Максимальный размер фото: 2Мб",
  wrongPhotoFormat: "Не поддерживаемый формат фото",
  supportedPhotoFormats: "Поддерживаемые форматы фото: JPEG, JPG, PNG",
  "A final report about harvesting": "Итоговый отчет по уборке/работе",
  "A daily report about harvesting (compact)": "Посуточный отчет по уборке/работе (компактный)",
  "A final report about used time": "Итоговый отчет по использованию времени",
  "An overall report about operation time": "Суммарный отчет по наработкам",
  "Daily Cleaning/Work Report (KYK)": "Посуточный отчет по уборке/работе (КУК)",
  "A final report about harvesting (compact)": "Итоговый отчет по уборке/работе (компактный)",
  "A final report about machine": "Итоговый отчет по машинам",
  "A daily report about used time": "Посуточный отчет по использованию времени",
  "A daily report about harvesting": "Посуточный отчет по уборке/работе",
  "A daily report about machine": "Посуточный отчет по машинам",
  "A report about machine": "Отчет по машине",
  "A final report agricultural operations": "Итоговый отчет по с/о",
  "A detail report agricultural operations": "Детальный отчет по с/о",
  "A report of fuel": "Отчет по топливу",
  "Filling/Drain": "Сливы / заправки",
  export_of_report: "Экспорт отчёта",
  export_of_reports: "Экспорт отчётов",
  "Please select unit(s) and time period": "Необходимо выбрать машину или несколько машин и период для экспорта",
  by_model: "По мод.",
  by_active: "По актв.",
  not_of_data: "Нет данных",
  selecting: "Выбрать все",
  deselecting: "Убрать все",
  "Navigation menu by pages": "Меню навигации по страницам",
  "profile settings": "настройки профиля",
  "farmings list": "Список хозяйств",
  "Change zoom of map Can use scroll of mouse or multitouch": "Изменение масштаба карты. Можно воспользоваться скролом мыши или развести/свести два пальца на карте",
  "Switch on/ switch off fullscreen mode": "Включение/выключение полноэкранного режима",
  "Show center of a map": "Центрирование карты",
  "The selection type a map": "Выбор типа карты",
  "The map show all machines Click on machine, to open information about this machine": "Карта с отображением всех доступных машин. Чтобы посмотреть информацию по конкретной единице техники, необходимо кликнуть левой кнопкой мыши на иконку",
  "Cost fuel for regimes all machines": "Затраты топлива по режимам для всех единиц",
  "Percent of time, when machines had active regime": "Процентное время нахождения всех единиц в режимах",
  "serial n": "Серийный №",
  "Start of fuel": "Начало бак, л.",
  "End of fuel": "Конец бак, л.",
  filling: "Заправлено бак, л.",
  drain_of_day: "Слито бак, л.",
  "summFuel": "Общий расход, л.",
  "summFuelMove": "Расход в транспортном режиме, л.",
  "summFuelComb": "Расход в уборке/работе, л.",
  "summFuelStand": "Расход в простое, л.",
  "fuelHour": "Средний расход, л/ч.",
  "engineTime": "Время работы ДВС, ч.",
  "engineTimeMove": "Время в транспортном режиме, ч.",
  "engineTimeComb": "Время в уборке/работе, ч.",
  "engineTimeStop": "Время в простое, ч.",
  "phone": "Телефон",
  "clear": "Очистить",
  "clear filter": "Очистить фильтр",
  "fixed options of filter": "Закрепить параметры фильтрации",
  "M0": "Нет связи",
  "M2": "Выключен",
  "M5": "Простой с вкл. агрегатами",
  "M6": "Простой с вкл. агрегатами и полным бункером",
  "M1": "Дорожный режим",
  "M3": "Простой с вкл. двигателем",
  "M4": "Рабочий режим",
  Idle: "Простой",
  UPLOAD: "Выгрузка",
  BUNKER_FULL: "Бункер заполнен",
  details: "Детализация",
  FILING: "Заправка",
  DRAIN: "Слив",
  All: "Все",
  "All machines": "Вся техника",
  "harvesting machinery": "Уборочная техника",
  "sowing machinery": "Посевная техника",
  "tractor": "Тракторы",
  "clear_filter": "Очистить фильтрацию",
  critical: "Критичное",
  not_critical: "Не критичное",
  informational: "Информационное",
  service: "Тех. обслуживание",
  engine: "Двигатель",
  fuel_system: "Топливная система",
  adapter_reaper: "Адаптер и жатка",
  reaper: "жатка",
  inclined_camera_feeder: "Наклонная камера и питатель",
  cabin: "Кабина",
  thresher: "Молотилка ЗУК и измельчитель КУК", //Thresher ZUK and chopper KUK
  cleaning: "Очистка",
  chassis: "Ходовая часть",
  hopper: "Бункер",
  stacker: "ИРС и копнитель",
  hydraulics: "Гидравлика",
  electrics: "Электрика и электроника", //Electrics and electronics
  notification: "Уведомление",
  harvesting: "Уборка",
  hopper_full: "Бункер заполнен",
  unloading: "Выгрузка",
  "Loading data": "Загрузка данных",
  downtime: "Простои",
  "idle_time_<_1": "простой с включенным ДВС до 1 мин",
  "idle_time_>_1": "простой с включенным ДВС более 1 мин",
  "stop_<_1": "остановка менее 1 минуты",
  "stop_1_10": "остановка от 1 до 10 минут",
  "stop_>_10": "остановка более 10 минут",
  summary: "Итоговый",
  detail: "Детальный",
  agriculture: "Культура",
  rating: "Отсортировать по рейтингу",
  "ON_UPLOAD": "Выгрузка",
  "ON_100": "Бункер заполнен",
  "group": "Групповой",
  "single": "Одиночный",
  "Set a date": "Задать дату",
  "Indicators at moment": "Текущие показатели",
  "Technique in testing": "Испытуемая техника",
  "Maintenance": "Техническое обслуживание",
  "Aggregates": "Агрегаты",
  "Status at now": "Текуший статус",
  "Move speed of combine": "Скорость движения комбайна",
  "Level fuel in tank": "Уровень топлива в баке",
  "Performance at now": "Текущая производительность",
  "is active": "Активно",
  "not active": "Не активно",
  "download excel": "Скачать (excel)",
  "report cleaning": "Отчёт по намолоту",
  "report unloading": "Отчёт по выгрузкам",
  "report equipment": "Отчеты о наработке оборудования",
  "report performance": "Производительность",
  "make graphics": "Построить графики",
  "Contact form": "Форма обратной связи",
  "Reasons contact": "Причины обращения",
  "Service": "Служба",
  "Contact phone": "Номер телефона",
  "Message": "Сообщение",
  "Serial number machine": "Серийный номер машины",
  "Type technics": "Тип техники",
  "submit": "Отправить",
  "profile": "Профиль",
  "download app": "Загрузить приложение",
  "Load photo": "Загрузить фото",
  "noname": "Имя не найдено",
  "Not found": "Ничего не найдено",
  "noemail": "E-mail не найден",
  "language": "Язык",
  "ru": "Русский",
  "en": "English",
  "fr": "Le français",
  "de": "Deutsch",
  "Serial number search": "Поиск по серийному номеру",
  "For the current day": "За текущие сутки",
  "feedback": "Связаться",
  "period": "Данные за",
  "sprayers": "Опрыскиватели",
  "mode": "Режим",
  "level fuel, l": "Уровень топлива, л",
  "fuel graphics": "График топлива",
  "creating geozones": "Создание геозон",
  "area": "Площадь",
  "Last point": "Последняя точка",
  "Long": "Длина",
  "km": "км",
  "Save geozone": "Сохранить геозону",
  "Active point": "Активная точка",
  "Continue": "Продолжить",
  "Delete point": "Удалить точку",
  "shape": "Загрузка shape файла.",
  "Turning on the display of directions on the map": "Включение показа направления на карте",
  "Select map tile": "Выбор подложки карты",
  "Geozone edit": "Редактирование геозоны",
  "New geozone": "Новая геозона",
  "Name geozone": "Название геозоны",
  "Do not display": "Не отображать",
  "Convective precipitation": "Конвективные осадки",
  "Precipitation intensity": "Интенсивность осадков",
  "Accumulated precipitation": "Накопленные осадки",
  "Accumulated precipitation - rain": "Накопленные осадки - дождь",
  "Accumulated precipitation - snow": "Накопленные осадки - снег",
  "Depth of snow": "Глубина снега",
  "Wind speed at an altitude of 10 meters": "Скорость ветра на высоте 10 метров",
  "Joint display of speed wind and wind direction": "Скорости ветра (цвет) и направления ветра (стрелки)",
  "Atmospheric pressure on mean sea level": "Атмосферное давление на среднем уровне моря",
  "Air temperature at a height of 2 meters": "Температура воздуха на высоте 2 метра",
  "Temperature of a dew point": "Температура точки росы",
  "Soil temperature 0-10 сm": "Температура почвы 0-10 см",
  "Soil temperature >10 сm": "Температура почвы > 10 см",
  "Relative humidity": "Относительная влажность",
  "Cloudiness": "Облачность",
  "Start": "Начало",
  "Finish": "Конец",
  "sputnic": "Вид со спутника",
  "hybrid": "гибрид",
  "terrain": "снимки",
  "Engine load": "Нагрузка двигателя",
  "Show in list": "Показать в списке",
  "No location data": "Нет данных по местоположению",
  "from": "От",
  "to": "До",
  "good day": "Добрый день",
  "User profile page": "Страница профиля пользователя",
  "Track in geozone": "Трек в геозоне",
  "Scaling": "Масштабирование",
  "Select the period of the show track": "Выбор периода показа трека",
  "No data for the this period": "Нет данных за указанный период",
  "Attention! Request for notifications is limited to": "Внимание! Запрос по уведомлениям ограничен",
  "days Please change the request parameters": "днями. Измените, пожалуйста, параметры запроса",
  "Track by mode": "Трек по режимам",
  "Track by speed": "Трек по скорости",
  "Track by load": "Трек по нагрузке",
  "Delete track": "Удалить трек",
  "Track control panel (select track type, delete track)": "Панель управления треками (выбор типа трека, удаление трека)",
  "Geozone name": "Имя геозоны",
  "Time of entry": "Время въезда",
  "Departure time": "Время выезда",
  "Time in geozone": "Время в геозоне",
  "Current status of the vehicle": "Текущий статус техники",
  "Make and model of technology": "Марка модель техники",
  "Level fuel": "Уровень топлива",
  "Track building": "Построение трека",
  "Track": "Отслеживать",
  "Enable / Disable the display of technology on the map": "Включение/выключение показа техники на карте",
  "Total fuel spent on all available units": "Общее затраченное топливо по всем доступным единицам",
  "Fuel consumption by mode for all units": "Затраты топлива по режимам для всех единиц",
  "main diagram": "Общая убранная / обработанная площадь и диаграмма затраченного времени на каждый режим по всем машинам",
  "data for current day": "данные указаны за текущие сутки",
  "Show / hide geofzonee on map": "Показать/скрыть показ геозоны на карте",
  "Select map tool": "Выберите тип карты",
  "Show / hide geozone detail information": "Показать/скрыть детальную информацию о геозоне",
  "Geozone type": "Тип геозоны",
  "Delete": "Удалить",
  "Delete geozone": "Удалить геозону",
  "Edit geozone": "Редактировать геозону",
  "Enable geozone editing mode": "Включение режима редактирования геозоны",
  "save": "Сохранить",
  "cancel": "Отменить",
  "Name": "Имя",
  "Surname": "Фамилия",
  "middle name": "Отчество",
  role: "Должность",
  "By mode": "По режимам",
  "By days": "По суткам",
  "Analysis of working time by mode": "Анализ рабочего времени по режимам",
  "Analysis of working time by day": "Анализ рабочего времени по дням",
  "Graph of the analysis of working time by group / unit of equipment for the selected period": "График анализа рабочего времени по группе/единице техники за выбранный период",
  "Graph of the analysis of working time for the group / unit of equipment for the selected period It is possible to turn on / off the display of modes": "График анализа рабочего времени по группе/единице техники за выбранный период. Есть возможность включения/выключения показа режимов",
  "Fuel in the tank at the beginning of the day": "Топливо в баке на начало суток",
  "Fuel in the tank at the end of the day": "Топливо в баке на конец суток",
  "Refills per day": "Заправки за сутки",
  "Total consumption": "Общий расход",
  "Consumption in transport mode": "Расход в транспортном режиме",
  "Consumption in cleaning / work": "Расход в уборке/работе",
  "Idle flow": "Расход в простое",
  "Average consumption": "Средний расход",
  "ICE working time": "Время работы ДВС",
  "Time in transport mode": "Время в траспортном режиме",
  "Time in cleaning / work": "Время в уборке/работе",
  "Idle time": "Время в простое",
  "Table filling/drain": "Таблица сливов/заправок",
  "Volume": "Объём",
  "Other": "Прочие",
  "Combine Harvesters": "Зерноуборочные комбайны",
  "Forage Harvesters": "Кормоуборочные комбайны",
  "Power facilities": "Энергосредства",
  "Tractors": "Тракторы",
  "Sprinklers": "Опрыскиватели",
  "Treated area": "Обработанная площадь",
  "total running time": "Суммарное время работы",
  "Distance traveled": "Пройденный путь",
  "Engine running time": "Наработка двигателя",
  "Fuel consumption": "Расход топлива",
  "Average time performance": "Средняя производительность по времени",
  "Average performance sum": "Средняя производительность суммарная",
  "Average performance run mode": "Средняя производительность в рабочем режиме",
  "Yes": "Да",
  "No": "Нет",
  "Work performance graph": "График показателей работы",
  "The choice of parameters for display on the graph (with the ability to change color)": "Выбор параметров для отображения на графике (с возможностью смены цвета)",
  "Select graphics": "Выберите графики",
  "Types de rapports disponibles pour exportation dans un fichier Export disponible au format Excel": "Виды отчетов, доступные для экспорта в файл. Доступен экспорт в формат excel",
  "Download": "Скачать",
  "The number of units in different modes in the household": "Количество единиц в различных режимах в хозяйстве",
  "Department of service vehicles": "Департамент сервисного обслуживания техники",
  "Spare Parts Sales Department": "Департамент продаж запасных частей",
  "Support Agrotronic": "Служба поддержки Агротроник",
  "Mower": "Косилки",
  "Appearance vehicles": "Внешний вид техники",
  "Current technical information: percentage of fuel tank filling, current working status of the equipment, date and time of the last activity of the equipment": "Текущая информация по технике: процент заполнения топливного бака, текущий статус работы техники, дата и время последней активности техники",
  "Activity status of the tracking function (if selected, the tracking mode for this unit of equipment is turned on and transferred to the map)": "Статус активности функции слежения техники (при выборе происходит включение режима слежения за данной единицей техники и переход на карту)",
  "Refresh": "Обновить",
  "Detailed parameters of the equipment": "Подробные параметры работы техники",
  "List of user geozones with the search function by name": "Список геозон пользователя с функцией поиска по названию",
  "List of households and units of user equipment with the search function by name": "Список хозяйств и единиц техники пользователя с функцией поиска по названию",
  "Toggle viewing of objects on the map": "Переключение режима просмотра объектов на карте",
  "Event name": "Название события",
  "Quantity for the period": "Количество за период",
  "Duration for the period": "Продолжительность за период",
  "Event start date": "Дата начала события",
  "Event start time": "Время начала события",
  "Event end time": "Время окончания события",
  "Event duration": "Продолжительность события",
  "Event location": "Местоположение события",
  "Machine condition for the selected period": "Состояние машины за выбранный период",
  "Report Type Selection Menu": "Меню выбора типа отчета",
  "Select one or more values": "Выбор одного или нескольких значений",
  "Time period selection": "Выбор периода времени",
  "Apply selected parameters in the filter": "Применение выбранных параметров в фильтре",
  "Search the machine by serial number": "Поиск машины по серийному номеру",
  "Select one unit of equipment": "Выбор одной единицы техники",
  "Choice of equipment / group of equipment": "Выбор техники/группы техники",
  "Single parameter selection": "Выбор одиночного параметра",
  "wrong login or password": "Неверный логин или пароль",
  "Something went wrong. Error code": "Что то пошло не так. Код ошибки",
  "Login": "Логин",
  "Password": "Пароль",
  "Entry": "Вход",
  "Demo mode": "Демонстрационный режим",
  "Vechicle info": "Информация о машине: иконка типа машины (цвет: серый – «Нет связи», чёрный – «Выключен» (работа без включённого зажигания), красный – «Простой с вкл. ДВС», синий – «Движение», зелёный – «Уборка/Работа»). При нажатии на название модели или серийный номер переход на страничку с подробной информацией о машине.",
  "The time of the first start of the engine for the current day": "Время первого пуска ДВС за текущие сутки",
  "Date and time of the last communication session": "Дата и время последнего сеанса связи",
  "Pie chart modes of operation for the current day": "Круговая диаграмма режимов работы техники за текущие сутки",
  "Average speed of equipment in the mode of combining / working for the current day": "Средняя скорость техники в режиме комбайнирования/работы за текущие сутки",
  "Fuel consumption for the current day": "Расход топлива техники за текущие сутки",
  "Cleaned / treated area for the current day": "Убранная/обработанная площадь за текущие сутки",
  "Productivity of technology for the current day": "Производительность техники за текущие сутки",
  "Tracking Button": "Кнопка перехода к слежению",
  "Notification text": "Текст уведомления",
  "Notification category": "Категория уведомления",
  "Date and time start of notification": "Дата и время взвода уведомления",
  "Date and time finish of notification": "Дата и время сброса уведомления",
  "Duration of notification": "Длительность уведомления",
  "Notification location": "Местоположение уведомления",
  "List of farm equipment (indicating the number of monitored equipment)": "Список техники хозяйства (с указанием количества отслеживаемой техники)",
  "dont machine": "Машина не найдена или не принадлежит пользователю",
  "Timezone": "Часовой пояс",
  "Phone": "Телефон",
  "Are you sure you want to delete the geozone": "Вы действительно хотите удалить геозону?",
  "Currently edit imported track do not support": "Редактирование импортированных геозон не поддерживается в данной версии",
  "Productivity map": "Карта урожайности",
  "Productivity map wet KUK": "Карта урожайности КУК по влажной массе",
  "Productivity map dry KUK": "Карта урожайности КУК по сухой массе",
  "Productivity map ZUK": "Карта урожайности ЗУК",
  "Moisture map ZUK": "Карта влажности ЗУК",
  "Moisture map KUK": "Карта влажности КУК",
  "Moisture": "Влажность",
  "%": "%",
  "Productivity": "Урожайность",
  "Create map": "Построить карту",
  "Download map": "Скачать карту",
  "Part width": "Ширина ячейки",
  "Choose vehicles": "Выбрать машины",
  "Create": "Создать",
  "Planning": "РСМ Роутер",
  "Agrotronic": "Агротроник",
  "Alerts": "Оповещения",
  "Fields": "Поля",
  "Production operations": "Производственные операции",
  "Technological maps": "Технологические карты",
  "Alert tasks": "Задания на оповещения",
  "Inbox": "Входящие",
  "Catalogs": "Справочники",
  "Phone number must be at least 11 characters": "Номер телефона должен быть не менее 11 символов",
  "holding": "Холдинг",
  "Users": "Сотрудники",
  "Farms": "Организации",
  "AdminPanel": "Адм. панель",
  "Organizations types": "Типы организаций",
  "Roles": "Роли",

  "km/h": "км/ч",
  "l": "л",
  "ha/h": "Га/ч",
  "ha": "Га",
  "ms": "мс",
  "h": "ч",
  "m": "м",
  "min": "мин",
  "t/ha": "т/Га",
  "cwt/ha": "ц/Га",
  "T": "т",
  // MAP
  "fullscreen": "Развернуть карту на весь экран",
  "exit fullscreen": "Выйти из полноэкранного режима",
  "zoom in": "Приблизить",
  "zoom out": "Отдалить",
  "Search the machine": "Поиск машины",
  "Filter": "Фильтр",
  contact_information: "Контактная информация",
  iAcceptTerms: "Продолжая использовать АПК Агротроник Вы соглашаетесь с условиями",
  termsOfUse: "пользовательского соглашения",
  deleteInfo: "Удалить данные",
  deleteInfoQuestion: "Вы действительно хотите удалить все данные?",
  "Apply filter": "Применить фильтр",
  "of": "из",
  "Rows per page": "Строк на странице",
  "Incorrect time period": "Выбран неверный период времени",
  maxVehicleCountRequest: "Максимальное число машин для анализа:",
  "The weather info currently unvailable": "Сервис погоды временно недоступен",
  "Create geozone for receive weather info": "Создайте геозону, чтобы получать информацию о погоде",
  "square": "Площадь",
  "Total amount of cultivated area": "Общее количество убранной площади",
  "All units": "Всего техники",
  "unit": "машин",
  "unit_plural": "машины",
  "Maintance": "Техническое обслуживание",
  "weather": "Погода",
  "Widgets": "Библиотека виджетов",
  "antialiasing": "Сглаживание",
  "Move forward": "Вперед",
  "Neutral gear": "Нейтраль",
  "Back": "Назад",
  "Parking": "Парковка",
  "Deactivated": "Деактивирована",
  "Activated in the automatic NC hold mode": "Удержание НК",
  "Activated in the mode of automatic maintenance of the cut height": "Поддержание высоты среза",
  "Activated in the automatic pressure maintenance mode": "Поддержание давления",
  predictUnloading: 'Предполагаемая выгрузка',
  "Download app for any device": 'Скачайте приложение для любого устройства',
  "download apk": "Загрузить APK",
  "license plate": "Гос. номер",
  brand: "Марка",
  allowed: "Разрешено",
  forbidden: "Запрещено",
  transport: "Транспортное средство",
  transports: "Транспортные средства",
  sht: "Сельскохозяйственная техника",
  "Error load file: need type file zip": "Ошибка загрузки файла: допустимый тип .zip",
  "Error load file: need type file txt": "Ошибка загрузки файла: допустимый тип .txt",
  "Error load file": "Ошибка загрузки файла.",
  "Error creating file": "Ошибка при создании файла.",
  "Need file types": "Допустимые типы файлов:",
  "File downloaded": "Файл загружен",
  "File read error": "Ошибка чтения файла",
  "reporting service is not available": "Сервис отчётов не доступен",
  "Select data source": "При включении данной функции, карты урожайности и влажности на участках, где нет данных от РСМ  Агротроник Пилота (Планшета), строятся на основании данных от штатной бортовой системы",
  "Select type of map": "Выберите тип карты точного земледелия",
  "track service is not available": "Сервис построения трека не доступен",
  "service is not available": "Сервис не доступен",
  "Coordinates are missing in the file": "В файле отсутствуют координаты",
  "Shape file is not correct": "Ошибка в shape файле",
  "Shape file have not supported type": "Shape файл данной структуры не поддерживается. В данной версии поддерживаются только структуры типа Polygon, MultiLineString, LineString, MultiPoint",
  "num of unloading": "Кол-во выгрузок",
  "osht": "ОСХТ",
  "sht_abbr": "СХТ",
  "field": "Поле",
  "operator": "Оператор",
  "no data": "Нет данных за выбранный период",
  "operating time": "Наработка",
  "eh": "Моточасы",
  "aggregate": "Агрегат",
  "aggregates": "Агрегаты",
  "total": "Итого",
  "Unknown transport": "НЕИЗВЕСТНОЕ ТС",
  "Unknown agriculture": "Неизвестная культура",
  "value must be": "Значение должно быть",
  "greater than previous one": "больше предыдущего",
  "less than following": "меньше следующего",
  "value cannot be less than 0": "Значение не может быть меньше 0",
  "403 FORBIDDEN \"Too many machines selected\"": "Слишком много машин выбрано",

// Культуры
  "arrhenatherum": "Райграс высокий",
  "barley": "Ячмень",
  "barley spring": "Ячмень яровой",
  "barley winter": "Ячмень озимый",
  "bean": "Бобы",
  "buckwheat": "Гречиха",
  "castor-oil plant": "Клещевина",
  "chick-pea": "Нут",
  "clover": "Клевер луговой",
  "corn long": "Кукуруза длинная средней влажности",
  "corn long dry": "Кукуруза длинная сухая",
  "corn long wet": "Кукуруза длинная влажная",
  "corn short": "Кукуруза короткая средней влажности",
  "corn short dry": "Кукуруза короткая сухая",
  "corn short wet": "Кукуруза короткая влажная",
  "cotton": "Хлопчатник",
  "dactylis glomerata": "Ежа сборная",
  "festuca pratensis": "Овсяница луговая",
  "flax": "Лён",
  "grass": "Трава",
  "grass dry": "Трава сухая",
  "grass normal": "Трава средней влажности",
  "grass wet": "Трава влажная",
  "haricot": "Фасоль",
  "hemp": "Конопля",
  "lentil": "Чечевица",
  "lucerne": "Люцерна",
  "maize corn": "Кукуруза на зерно",
  "mustard": "Горчица",
  "oat": "Овёс",
  "panicum": "Просо",
  "papaver": "Мак",
  "pea": "Горох",
  "rape": "Рапс",
  "rice": "Рис",
  "rye": "Рожь",
  "safflower": "Сафлор",
  "sesame": "Кунжут",
  "silage": "Силос соломенно-зерновой",
  "sorghum": "Сорго",
  "soy": "Соя",
  "sugar-beet": "Семенники сахарной свёклы",
  "sunflower": "Подсолнечник",
  "triticale": "Тритикале",
  "user1": "Прочее 1",
  "user2": "Прочее 2",
  "user3": "Прочее 3",
  "user4": "Прочее 4",
  "wheat": "Пшеница",
  "wheat spring": "Пшеница яровая",
  "wheat winter": "Пшеница озимая",
  "User manual Agrotronic Pilot": "Руководство пользователя Агротроник Пилот",
  "User manual Agrotronic": "Руководство пользователя Агротроник",
  "Agrotronic Administrator Tools Description": "Описание инструментов администратора АПК Агротроник",
  "User manual cruise control KUK": "Руководство пользователя Круиз контроль КУК",
  "Humidity sensor FH": "Руководство пользователя Картирование урожайности КУК",
  "n/d": "н/д",
  "about field": "о поле",
  "about operator": "о операторе",
  "about technique": "о технике",
  "about task": "о задании",
  "about operation": "о операции",
  "operation": "Операция",
  "task": "Задание",
  "all sht": "Вся сельскохозяйственная техника",
  "operators": "Операторы",
  "all fields": "Все поля",
  "all operators": "Все операторы",
  "find operator": "Найти оператора",
  "find field": "Найти поле",
  "find technique": "Найти технику",
  "formed": "Сформировано",
  "performance report for the period": "Отчёт по производительности за период",
  "the report is generated with filters": "Отчёт сформирован с фильтрами",
  "twenty-four hours": "сутки",
  "hours": "часы",
  "minutes": "минуты",
  "incl": "в т.ч.",
  "total according to the report": "всего по отчёту",
  "driver report": "Отчёт о водителях",
  "title": "Название",
  "date added": "Дата добавления",
  "change history": "История изменений",
  "changed to": "Изменено на",
  "changed": "Изменил",
  "date of change": "Дата изменения",
  "driver": "водитель",
  "biometrics changed": "изменена биометрия",
  "information": "Информация",
  "driver information": "Информация о водителе",
  "the report was generated without filters": "Отчёт сформирован без фильтров",
  "Threshing sensor used": "Используется датчик намолота",
  "n period": "№ периода",
  "startHarvesting": "Начало уборки",
  "endHarvesting": "Конец уборки",
  "periodHarvesting": "Период уборки",
  "harvested agriculture": "Убираемая агрокультура",
  "density": "Плотность, кг/куб.м",
  "Harvesting time for period": "Время уборки за период, ч",
  "Harvested crop for period": "Убранный урожай за период, т",
  "Harvesting time": "Время уборки",
  "Harvested crop": "Убранный урожай",
  "Date and time": "Дата и время",
  "Report": "Отчёт",
  "during the period": "за период",
  "Parameters": "Параметры",
  "Parameter": "Параметр",
  "Meaning": "Значение",
  "You can request the code again via": "Запросить код повторно можно будет через",
  "Confirm": "Подтвердить",
  "Send SMS code": "Отправить СМС-код",
  "SMS code": "СМС-код",
  "Confirmation": "Подтверждение",
  "Change phone": "Изменить телефон",
  "To confirm the action": "Для подтверждения действия необходимо ввести код подтверждения, он будет отправлен в СМС сообщении на ваш телефон.",
  "To confirm the changes": "Для подтверждения изменений необходимо ввести код подтверждения, он будет отправлен в СМС сообщении на ваш телефон.",
  "To change the phone number, you must enter a confirmation code": "Для изменения номера телефона необходимо ввести код подтверждения, он будет отправлен в СМС сообщении на новый номер телефона",
  "Can not be empty": "Не может быть пустым",
  "Invalid address format": "Адрес электронной почты указан в неверном формате",
  "The phone number is specified in the wrong format": "Номер телефона указан в неверном формате",
  "Two-factor authentication": "Двухфакторная аутентификация",
  "Error confirming code": "Ошибка подтверждения",
  "Error occurred during validation process": "Возникла ошибка в процессе валидации",
  "Wrong code": "Неверный код подтверждения",
  "Phone number successfully changed": "Номер телефона успешно изменен",
  "Changes successfully saved": "Изменения успешно сохранены",
  "Display machine data": "Отображать данные по машинам",
  "Map Legend Management": "Управление легендой карты",
  "Error loading calendar coloring pages": "Ошибка загрузки раскраски календаря",
  "Error while receiving data": "Ошибка при получении данных",
  "Serial number": "Серийный номер",
  "Cocked": "Взведено",
  "Reset": "Сброшено",
  "Duration, hh:mm:ss": "Длительность, чч:мм:сс",
  "hh:mm:ss": "чч:мм:сс",
  "Username is already in use, use a different one": "Имя пользователя уже используется, используйте другое",
  "Login can contain only numbers and Latin letters": "Логин может содержать только цифры и латинские буквы",
  "Login cannot consist only of numbers": "Логин не может состоять только из цифр",
  "Login cannot be less than 6 characters": "Логин не может быть меньше 6 символов",
  "The password must contain at least 8 characters": "Не менее 8 знаков, обязательно содержать прописные и строчные латинские буквы, одну цифру и спецсимвол",
  "Password can only contain numbers, latin letters and special characters": "Пароль может содержать только цифры, латинские буквы и специальные символы",
  "Select a different report or change the filtering conditions": "Выберите другой отчет или измените условия фильтрации",
  "There is no data on which to build this report": "Нет данных по которым можно построить этот отчет",
  "Enter the code sent to the number": "Введите код, отправленный на номер",
  "Enter the code sent to the address": "Введите код, отправленный на адрес",
  "An SMS with a confirmation code will be sent to the phone": "На телефон {number} будет отправлено СМС с кодом для подтверждения",
  "To the address an email with a confirmation code will be sent": "На адрес {email} будет отправлено письмо с кодом подтверждения",
  "Only mobile numbers of the Russian Federation with the code +7": "Только мобильные номера РФ с кодом +7",
  "Phone Confirmation": "Подтверждение Телефона",
  "Email Confirmation": "Подтверждение Email",
  "Enter code": "Введите код",
  "On": "Вкл",
  "Event": "Событие",
  "Web": "Веб",
  "SMS": "CMC",
  "Action Confirmation": "Подтверждение действия",
  "To the address": "На адрес",
  "confirmation code will be sent": "будет отправлено письмо с кодом подтверждения",
  "To the phone": "На телефон",
  "SMS with a confirmation code will be sent": "будет отправлено СМС с кодом для подтверждения",

  //account block modal warning
  "Logging in using enterprise accounts will be blocked in the near future": "⚠️ Вход в систему с использованием аккаунтов предприятий будет заблокирован в ближайшее время",
  "Contact the administrator of the company or department to obtain a personal account": "Обратитесь к администратору предприятия или подразделения для получения персонального аккаунта",
  "The changes are aimed at improving data security and expanding user capabilities": "Изменения направлены на повышение защищенности данных и расширение возможностей пользователей",
  "Continue working": "Продолжить работу",
  "You have changed the display of machine data": "Вы изменили отображение данных по машинам. Обновите информацию, чтобы данные по урожайности отображались корректно",

  "Select at least one machine to build a field yield report": "Выберите хотя бы одну машину для построения отчета об урожайности поля",
  "Mapped": "Картировано",
  "Avg yield": "Средняя урожайность",
  "Total yield": "Убранный урожай",
  "The area of the geofence without taking into account the relief of the earth": "Площадь геозоны без учета рельефа земли",
  "The area of the geofence for which there is data on yield": "Площадь геозоны, по которым есть данные по урожайности",
  "Average yield of the mapped part of the geofence": "Средняя урожайность картированной части геозоны",
  "Indicative (estimated) data obtained on the yield of the field": "Ориентировочные (оценочные) данные полученные по урожайности поля",
  "Download the field yield report": "Скачать отчет урожайности поля",
  "Field Yield Report": "Отчёт урожайности поля",
  "Name geoZone": "Наименование геозоны",
  "Equipment (Equipment that was in the geofence during the specified period)": "Техника (Техника, которая находилась в геозоне в указанный период)",
  "Map type": "Тип карты",
  "Appearance": "Появление",
  "Elimination": "Устранение",
  "Tire pressure": "Давление в шинах",
  "Auto-guidance system": "Система автовождения",
  "View location is not available, the machine has not yet sent the coordinates": "Просмотр местоположения недоступен, машина пока не отправила координаты",
  "pcs": "шт",
  "RSM User Manual Auto-unloading KUK": "Руководство пользователя РСМ Автовыгрузка КУК"
};

export default ru;
