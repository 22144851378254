import { flatten } from 'ramda'
import { TMachine } from '../../models'

const progressColor = {
  green : 'green',
  red   : 'red',
  shadow: 'shadow',
  yellow: 'yellow',
}

type TParam = {
  key: 'FUEL'
  measure?: string
}

export type TDetailItem = {
  params: TParam[]
}

export const getFuelParams = (machine: TMachine, detailInformation: TDetailItem[]) => {
  let FUEL: number | string = '-'
  let FUELProgress = 0

  if(detailInformation && machine.data?.FUEL?.value != null) {
    machine.data.FUEL.measure = flatten(detailInformation.map(d => d.params)).find(d => d.key === 'FUEL')?.measure

    if(machine.data.FUEL.measure !== '%') {
      FUEL = Math.round(
        machine.data.FUEL.value < machine.data.FUEL.max ? machine.data.FUEL.value : machine.data.FUEL.max,
      )

      FUELProgress = FUEL / machine.data.FUEL.max * 100
    } else {
      const fuelValue = machine.data.FUEL.value < machine.data.FUEL.max
        ? machine.data.FUEL.value
        : machine.data.FUEL.max

      FUEL = fuelValue.toFixed(1)
      FUELProgress = fuelValue
    }
  }

  return { FUEL, FUELProgress }
}

export const getFuelColor = (FUELProgress: number) => {
  if(FUELProgress === 0) {
    return progressColor.shadow
  } else if(FUELProgress <= 20) {
    return progressColor.red
  } else if(FUELProgress <= 60 && FUELProgress > 20) {
    return progressColor.yellow
  } else {
    return progressColor.green
  }
}
