import { Button, DateSelector, icons, Spacer, Spinner } from '@agdt/agrotronic-react-components'
import moment from 'moment'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withTranslate } from 'react-redux-multilingual'
import * as actionNotification from 'reducers/notification/actions'
import { errorMessage } from 'services/notification'
import styled from 'styled-components'
import { TDateRange, TPeriod, TTranslate } from 'types'
import { colorsRegister } from 'utils'
import { useClickOutside } from '../../../services/hooks'

const { CalendarBlocksIcon } = icons

type TProps = {
  currentRegister: unknown
  dateRange: TDateRange
  handleTodayRange: () => void
  handleWeekRange: () => void
  isLoadingCalendarPainting: boolean
  localRange: TDateRange
  register: unknown
  registerValue: unknown
  setCurrentRegister: (register: unknown) => void
  setDateRange: (range: { typeRange: TPeriod, range: TDateRange }) => void
  setLocalRange: (range: TDateRange) => void
  setRegisterData: ({ month, year }: { month: number, year: number }) => void
  setTypeRange: (typeRange: TPeriod) => void
  translate: TTranslate
  typeRange: TPeriod
}

const DatePeriod = ({
  currentRegister,
  dateRange,
  handleTodayRange,
  handleWeekRange,
  isLoadingCalendarPainting,
  localRange,
  register,
  registerValue,
  setCurrentRegister,
  setDateRange,
  setLocalRange,
  setRegisterData,
  setTypeRange,
  translate,
  typeRange,
}: TProps) => {
  const [showCalendar, onShowCalendar] = useState(false)
  const ref = useRef(null)

  const handleOnSelectDate = useCallback(range => {
    const startDate = new Date().user.new(range[0])
    startDate.setHours(range[0].getHours(), range[0].getMinutes(), range[0].getSeconds(), range[0].getMilliseconds())

    const endDate = new Date().user.new(range[1])
    endDate.setHours(range[1].getHours(), range[1].getMinutes(), range[1].getSeconds(), range[1].getMilliseconds())

    setLocalRange([startDate, endDate])
  }, [setLocalRange])

  useClickOutside(ref, () => showCalendar && handleCancelRange())

  const toggleShowCalendar = useCallback(() => {
    if(!showCalendar) {setRegisterData({ month: moment(localRange[0]).month(), year: moment(localRange[0]).year() })}

    onShowCalendar(!showCalendar)
  }, [localRange, setRegisterData, showCalendar])

  const handleCancelRange = () => {
    switch(typeRange) {
      case 'today':
        handleTodayRange()
        break
      case 'week':
        handleWeekRange()
        break
      case 'range':
        setLocalRange(dateRange)
        break
      default:
        handleTodayRange()
    }

    onShowCalendar(false)
  }

  const handleAcceptRange = () => {
    if(localRange && localRange.length && moment(localRange[1]).isAfter(moment(localRange[0]))) {
      setTypeRange('range')
      onShowCalendar(false)
      setDateRange({ range: localRange, typeRange: 'range' })
    } else {
      errorMessage(translate('Incorrect time period'))
    }
  }

  const handleChangeMonth = useCallback(({ month, year }) => {
    setRegisterData({ month: month, year: year })
  }, [setRegisterData])

  const userDateRange = useMemo(() => [localRange[0], localRange[1]], [localRange])

  return (
    <CalendarContainer>
      <Button
        variant={typeRange === 'range' ? 'primary' : 'border'}
        width="auto"
        onClick={toggleShowCalendar}
      >
        <CalendarBlocksIcon
          size={24}
          color={typeRange === 'range' ? '#ffffff' : '#828282'}
        />

        <UnderIconText typeRange={typeRange}>
          {userDateRange[0].format('DD.MM.YYYY')} -{' '}
          {userDateRange[1].format('DD.MM.YYYY')}
        </UnderIconText>
      </Button>

      <DateSelectorWrapper showCalendar={showCalendar} ref={ref}>
        {isLoadingCalendarPainting && <>
          <SpinnerBackground/>
          <StyledSpinner color="#D10B41" size={31}/>
        </>}

        <DateSelector
          cbChangeMonth={handleChangeMonth}
          colorValues={registerValue}
          colors={colorsRegister}
          data={register}
          dataSelected={currentRegister}
          maxCurrentDate={true}
          onChange={handleOnSelectDate}
          onChangeData={setCurrentRegister}
          value={userDateRange}
          withData={true}
          withTime={true}
        />

        <ButtonContainer>
          <Button onClick={handleAcceptRange}>{translate('accept')}</Button>
          <Spacer size="50px" />
          <Button variant="border" onClick={handleCancelRange}>
            {translate('cancel')}
          </Button>
        </ButtonContainer>
      </DateSelectorWrapper>
    </CalendarContainer>
  )
}

const UnderIconText = styled.span<{ typeRange: TPeriod }>`
  font-size: 16px;
  line-height: 20px;
  color: ${({ typeRange }) => typeRange === 'range' ? '#ffffff' : '#373a3e'};
  margin-left: 16px;
`

const CalendarContainer = styled.div`
  position: relative;
`

const DateSelectorWrapper = styled.div<{ showCalendar: boolean }>`
  padding: 20px 10px;
  display: ${({ showCalendar }) => showCalendar ? 'static' : 'none'};
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2), 0 3px 16px rgba(0, 0, 0, 0.12),
    0 9px 12px rgba(0, 0, 0, 0.12);
  z-index: 1001;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 15px;
`

//@ts-expect-error
const mapStateToProps = ({ notification }) => {
  return {
    currentRegister          : notification.currentRegister,
    isLoadingCalendarPainting: notification.isLoadingCalendarPainting,
    register                 : notification.register,
    registerValue            : notification.registerValue,
  }
}

const mapDispatchToProps = {
  setCurrentRegister: actionNotification.setCurrentRegister,
  setRegisterData   : actionNotification.setRegisterData,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(DatePeriod))

const StyledSpinner = styled(Spinner)`
  position: absolute;
  left: 45%;
  top: 40%;
  z-index: 2;
`

const SpinnerBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  opacity: .7;
  z-index: 1;
`
