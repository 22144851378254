import { Avatar } from '@agdt/agrotronic-react-components'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { link } from 'router'
import { getStore } from 'stores/storesRegistry'
import styled from 'styled-components'
import { TTranslate } from 'types'
import TooltipD from 'ui/Tooltip'
import { CalendarIcon, EquipmentIcon, SpeedometerIcon } from '../icons'
import { TMachine } from '../models'
import { getFuelColor, getFuelParams, TDetailItem } from './helpers'
import Item from './Item'

const styles = {
  tooltips: {
    info: { bottom: 0, left: 318 },
  },
}

const notServiceValue = (v: string | number) => v !== 'NA' ? v : '-'

export type TDriver = {
  operatorFirstName?: string
  operatorPhotoSmall?: string
  operatorSurname?: string
  personNumber?: string
}

export type TEquipment = {
  modelName?: string
  width?: number
}

type TProps = {
  detailInformation: TDetailItem[]
  driver: TDriver | null
  equipment: TEquipment | null
  load: boolean
  machine: TMachine
  t: TTranslate
}

export const MainInfo = ({
  detailInformation,
  driver,
  equipment,
  load,
  machine,
  t,
}: TProps) => {
  const { FUEL, FUELProgress } = useMemo(
    () => getFuelParams(machine, detailInformation),
    [machine, detailInformation],
  )

  const color = useMemo(() => getFuelColor(FUELProgress), [FUELProgress])
  const status = machine.data.STATUS.value
  const householdUnit = getStore('householdUnits').getUnit(machine.imei)

  return <DetailsWrapper>
    <TooltipD
      text={
        t('Current technical information: percentage of fuel tank filling, current working status of the equipment, date and time of the last activity of the equipment')
      }
      style={styles.tooltips.info}
    />

    <DetailsDataWrapper>
      <Item
        title={t('fuel')}
        status={`${notServiceValue(FUEL)} ${machine.data.FUEL && FUEL !== '-' ? machine.data.FUEL.measure : ''}`}
      >
        <div className="detail-main__data-fiel">
          <div
            className={`detail-main__data-fiel-progres ${color}`}
            style={{ height: `${FUELProgress.toFixed(1)}%` }}
          />
        </div>
      </Item>

      <Item title={t('status')} status={t(`M${status}`)}>
        <div className={`detail-main__data-work color-${status}`}/>
      </Item>

      <Item
        title={t('last_data')}
        status={machine.data.ACTIVE && machine.data.ACTIVE.valueF
          ? notServiceValue(machine.data.ACTIVE.valueF)
          : '-'
        }
      >
        <CalendarIcon />
      </Item>

      <Item
        title={t('speed_at_now')}
        status={machine.data.SPEED && machine.data.SPEED.valueF ? notServiceValue(machine.data.SPEED.valueF) : '-'}
      >
        <SpeedometerIcon/>
      </Item>

      {machine.data.LATITUDE && machine.data.LONGITUDE && <Item
        load={load}
        title={t('watching')}
        status={householdUnit.isWatched ? t('is active') : t('not active')}
      >
        {!load
          ? <Link
            className={householdUnit.isWatched ? 'detail-main__data-watch active' : 'detail-main__data-watch'}
            onClick={() => householdUnit.setWatched()}
            to={`${link.households}?machine=${machine.name}`}
          />

          : <span className={householdUnit.isWatched ? 'detail-main__data-watch active' : 'detail-main__data-watch'}
          />
        }

        <TooltipD
          text={
            t('Activity status of the tracking function (if selected, the tracking mode for this unit of equipment is turned on and transferred to the map)')
          }

          style={{ top: '-8px' }}
        />
      </Item>}

      {equipment && <Item
        title={equipment.modelName || '-'}
        status={equipment.width || '-'}
      >
        <EquipmentIcon/>
      </Item>}

      {driver && <Item
        title={`${driver.operatorFirstName || ''} ${driver.operatorSurname || ''}`}
        status={driver.personNumber || '-'}
      >
        <Avatar
          size="28px"
          src={driver.operatorPhotoSmall && `data:image/png;base64, ${driver.operatorPhotoSmall}`}
        />
      </Item>}
    </DetailsDataWrapper>
  </DetailsWrapper>
}

const DetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const DetailsDataWrapper = styled.div`
  flex: 1 1;
`
