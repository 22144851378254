import { action, makeObservable, observable } from 'mobx'
import { isEmpty, isNil, propOr } from 'ramda'
import { FilterableListStore } from 'stores/generic/FilterableListStore'
import { TUnit } from 'types'

type TStatus = 1 | 2 | 4 | 8

export class NoticesUnitsListStore {
  @observable isLoading = false
  @observable sortByStatus: TStatus = 1

  list = new FilterableListStore<TUnit>()

  constructor() {
    makeObservable(this)

    this.list.setSearchDelegate((needed: string, row) => isEmpty(needed) || isNil(needed)
      || propOr<'', typeof row, string>('', 'name', row).toLowerCase().indexOf(needed.toLowerCase()) >= 0,
    )

    //@ts-expect-error
    this.list.setSortField(item => item.sumNotifications.find(S => S.status === this.sortByStatus)?.value || 0, -1)
  }

  @action
  setSortByStatus(status: TStatus) {
    this.sortByStatus = status

    // Пересортируем список при изменении значения сортировки
    this.list.sort()
  }

  @action

  //@ts-expect-error
  setLoading(isLoading) {
    this.isLoading = isLoading
  }
}
