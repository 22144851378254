import { urlApi } from 'constants/env'
import moment from 'moment'
import { all, intersection, isEmpty, isNil, map, mapObjIndexed, not, pipe, prop } from 'ramda'
import {
  pushIndicatorsReport,
  pushTrackReport,
} from 'reducers/reports/actions'
import { ReportType } from 'reducers/reports/state'
import { AppDispatch } from 'reducers/store'
import { flyTrack, pushTrack } from 'reducers/users/actions'
import { getTrack as getTrackRequest } from 'services/apiService/modules'
import { TTranslate } from 'types'
import normalizeTrack, {
  adaptationPoints,
  addCourse,
  PointInfo,
} from 'ui/Map/normalizeTrack'
import { getSensorsData } from '../services/apiService/modules'

const DELAY_OBSERVABLE_TRACK = 30
const DURATION_TIME_RANGE = 600000

export type RequestTrackData = {
  static?: boolean
  imei: number
  to: number | Date
  from: number | Date
  reportType?: Exclude<ReportType, ReportType.Time>
}

class RequestTrack {
  private cycleGetTrack: NodeJS.Timeout | null = null
  private imei: number = 0

  getTrack = (data: RequestTrackData, dispatch: AppDispatch, type: string = 'fly') => {
    data.static = true
    dispatch(getTrackAction(data, type))
  }

  observableTrack = (data: RequestTrackData, dispatch: AppDispatch, update?: boolean) => {
    if(update && data.imei === this.imei && this.cycleGetTrack) {
      clearInterval(this.cycleGetTrack)
    }

    this.imei = data.imei
    dispatch(getTrackAction(data))

    this.cycleGetTrack = setInterval(() => {
      data.to = moment().unix() * 1000
      data.from = data.to - DURATION_TIME_RANGE
      dispatch(getTrackAction(data, 'fly'))
    }, DELAY_OBSERVABLE_TRACK * 1000)
  }

  stopObservableTrack = (imei: number) => {
    if(this.imei === imei && this.cycleGetTrack) {
      clearInterval(this.cycleGetTrack)
    }
  }
}

export const requestTrack = new RequestTrack()

const getTrackAction = (data: RequestTrackData, type?: string) => async (dispatch: AppDispatch) => {
  const dateFrom = Number(data.from)
  const dateTo = Number(data.to)
  let todayDate = new Date()
  let inputDate = new Date(dateTo)
  const today = inputDate.setHours(0,0,0,0) === todayDate.setHours(0,0,0,0)

  try {
    const response = await getTrackRequest({
      from: new Date(dateFrom),
      imei: Number(data.imei),
      to  : new Date(dateTo),
    }) as PointInfo[]

    if(response.length) {
      const trackWithCourse = await addCourse(response)
      const adaptationPointsTrack = await adaptationPoints(trackWithCourse)

      switch(type) {
        case 'fly': {
          dispatch(flyTrack(adaptationPointsTrack, data.imei))
          break
        }

        case 'report': {
          if(data.reportType) {
            const getTypesTracks = await normalizeTrack(adaptationPointsTrack)
            dispatch(pushTrackReport({ data: getTypesTracks, imei: data.imei, type: data.reportType }))
          }

          break
        }

        default: {
          const getTypesTracks = await normalizeTrack(adaptationPointsTrack)
          dispatch(pushTrack(getTypesTracks, data.imei, data.static, false, today))
          break
        }
      }
    } else {
      console.log('Not have data in the time interval')
    }
  } catch(e) {
    console.log('--err', e)
  }
}

export const loadBlobFile = (blob: Blob, name: string, ext = 'xlsx') => {
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
  let newBlob = new Blob([blob], { type: blob.type })
  let url = window.URL.createObjectURL(newBlob)

  if(iOS) {
    const reader = new FileReader()

    reader.onload = function (){
      window.location.href = reader.result as string
      window.document.title = name
    }

    reader.readAsDataURL(newBlob)
  } else {
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style.display = 'none'
    a.href = url
    a.download = `${name}.${ext}`
    a.click()
  }

  window.URL.revokeObjectURL(url)
}

const isShow = pipe(isEmpty, not)

export const sortDetailData = (data: Record<string, object>, translate: TTranslate) => {
  const template = [
    {
      id   : 0,
      title: translate('Indicators at moment'),

      params: [
        'ON_EMPTY',
        'ON_UPLOAD',
        'ON_IRS',
        'ON_100',
        'ON_METALL_ON',
        'ON_STONE_ON',
        'ON_CLUTCH',
        'ON_CLUTCH_1',
        'ON_CLUTCH_2',
        'ON_CLUTCH_3',
        'ON_LIGHT',
        'ON_PROCESSING',
        'ON_VALVE_17',
        'ON_VALVE_16',
        'ON_VALVE_15',
        'ON_VALVE_14',
        'ON_VALVE_13',
        'ON_VALVE_12',
        'ON_VALVE_11',
        'ON_VALVE_10',
        'ON_VALVE_8',
        'ON_VALVE_7',
        'ON_VALVE_9',
        'ON_VALVE_4',
        'ON_VALVE_3',
        'ON_VALVE_6',
        'ON_VALVE_5',
        'ON_VALVE_2',
        'ON_VALVE_1',
        'ON_2',
        'ON_25',
        'ON_50',
        'ON_75',
      ],

      isShow,
    },
    {
      id    : 1,
      title : translate('Auto-guidance system'),
      params: [
        'FJD_ELSTEER_STATUS',
        'FJD_ELSTEER_LAT',
        'FJD_ELSTEER_LON',
        'FJD_ELSTEER_SPEED',
        'FJD_ELSTEER_DIR',
      ],
      isShow,
    },
    {
      id    : 2,
      title : translate('Tire pressure'),
      params: [
        'TIRE_PRESS_BL1',
        'TIRE_PRESS_BL2',
        'TIRE_PRESS_BR1',
        'TIRE_PRESS_BR2',
        'TIRE_PRESS_FL1',
        'TIRE_PRESS_FL2',
        'TIRE_PRESS_FR1',
        'TIRE_PRESS_FR2',
      ],

      isShow: pipe(map(prop('value')), all(isNil), not),
    },
    {
      id    : 3,
      title : translate('Maintenance'),
      params: ['TO_10', 'TO_50', 'TO_250', 'TO_500', 'TO_1000', 'TO_1500'],
      isShow,
    },
    {
      id    : 4,
      title : translate('activity'),
      params: [
        'AGRICULTURE',
        'AREA_PER_TIME',
        'AREA_PER_TIME_SH',
        'SUMM_AREA_SH',
        'INC_SUMM_AREA_SH',
        'SUMM_MAT_1',
        'INC_SUMM_MAT_1',
        'SUMM_MAT_2',
        'INC_SUMM_MAT_2',
        'SUMM_MAT_3',
        'INC_SUMM_MAT_3',
        'FIRST_SEEDING',
        'TIME_SEEDING',
        'TIME_MOVE_NO_SEEDING',
        'AVER_SPEED_SEEDING',
        'LAST_SEEDING',
        'AVER_SPEED_COMBINE',
        'AVER_SPEED_MOVE',
        'AVER_SPEED',
        'ADAPTER',
        'FIRST_MOTOR',
        'LAST_MOTOR',
        'FIRST_COMBINE',
        'LAST_COMBINE',
        'FIRST_MOVE',
        'LAST_MOVE',
        'TIME_HARVEST',
        'TIME_MOVE_NO_HARVEST',
        'TIME_STOP_HARVEST',
        'TIME_OFF',
        'TIME_STOP_DON',
        'TIME_STOP',
        'TIME_NO_LINK',
        'TIME_STOP_HARVEST_FULL',
        'CURR_PERF',
        'ON_WAY',
        'ON_BRAKE',
        'SUMM_PATH',
        'INC_SUMM_PATH',
        'SUMM_AREA',
        'INC_SUMM_AREA',
        'SUMM_AREA_IZM',
        'INC_SUMM_AREA_IZM',
        'SUMM_AREA_NO_IZM',
        'INC_SUMM_AREA_NO_IZM',
        'SUMM_UPLOAD',
        'INC_SUMM_UPLOAD',
        'INC_SUMM_BUNKER',
        'SUMM_MOLOT_TON',
        'INC_SUMM_MOLOT_TON',
        'NOZ_STATUS',
        'MOVE_PATTERN',
        'SPILLED_OUT',
        'SPILL_PRESET',
        'SPILL_FACT',
        'FLOWMETER',
        'LIQUID_DENSITY',
        'FLOWMETER_CONST',
        'ON_CRUISE',
        'CURR_PERF_HA',
        'TRANSMISSION',
        'CURR_TRANSMISSION',
        'CURR_PERF_HA',
        'FUEL_HA',
        'SUMM_WORK_TIME',
      ],
      isShow,
    },
    {
      id    : 5,
      title : translate('engine'),
      params: [
        'FR_MOTOR',
        'AVER_LOAD_COMBINE',
        'LOAD',
        'TMP_MOTOR',
        'PR_MOTOR',
        'TMP_MOTOR',
        'LOAD',
        'SUMM_MOTOR',
        'INC_SUMM_MOTOR',
        'FR_TPO',
        'TMP_INTAKE_MANIFOLD',
        'PR_INTAKE_MANIFOLD',
        'ON_TPO',
        'ON_TPO_FRONT',
        'FR_TPO_FRONT',
      ],
      isShow,
    },
    {
      id    : 6,
      title : translate('fuel'),
      params: [
        'FUEL',
        'FUEL_HOUR',
        'SUMM_FUEL',
        'INC_SUMM_FUEL',
        'SUMM_FUEL_COMB',
        'INC_SUMM_FUEL_COMB',
        'SUMM_FUEL_MOVE',
        'INC_SUMM_FUEL_MOVE',
        'SUMM_FUEL_STOP',
        'INC_SUMM_FUEL_STOP',
        'SUMM_FUEL_TON',
        'SUMM_FUEL_PARK',
        'SUMM_FUEL_HA',
        'SUMM_FUEL_HOUR',
      ],
      isShow,
    },
    {
      id    : 7,
      title : translate('Aggregates'),
      params: [
        'VOLTAGE',
        'WIDTH',
        'COPY_MODE',
        'FORCE_PLAN',
        'FORCE_FACT',
        'DEEP_PLAN',
        'FR_VENT_SH',
        'DEEP_FACT',
        'HARVEST_SH',
        'LEVEL_1',
        'LEVEL_2',
        'LEVEL_3',
        'ZM_1',
        'ZM_2',
        'ZM_3',
        'ON_CLUTCH_MODE',
        'NUM_MATERIALS',
        'BUNKER_1',
        'BUNKER_2',
        'BUNKER_3',
        'NORMA_1',
        'NORMA_2',
        'NORMA_3',
        'NORMA_PLAN_1',
        'NORMA_PLAN_2',
        'NORMA_PLAN_3',
        'NORMA_FACT_1',
        'NORMA_FACT_2',
        'NORMA_FACT_3',
        'TMP_GST_MOVE',
        'SUMM_MOLOT',
        'INC_SUMM_MOLOT',
        'SUMM_CHASSIS',
        'INC_SUMM_CHASSIS',
        'SUMM_IRS',
        'INC_SUMM_IRS',
        'TMP_GST_ROTOR',
        'ON_NK',
        'ON_NK_REVERS',
        'ON_NK_TRANSP',
        'ON_PLV_TRANSP',
        'ON_VIBRATOR',
        'ON_DEKA_RESET',
        'ON_POWER',
        'ON_ROTOR_1',
        'ON_ROTOR_2',
        'ON_IRS_IZM',
        'ON_IRS_VALOK',
        'ON_MOTOVIL',
        'FR_SALTBINDER',
        'FR_VAL_1',
        'FR_VAL_2',
        'CUTTING',
        'LEVEL_PRES',
        'MOISTURE',
        'CORRECTION_MOISTURE',
        'GAP_CYL_MOLOT',
        'FR_CYL_IZM',
        'RETURN',
        'GAP_CYL_SEPAR',
        'FR_CYL_SEPAR',
        'FR_VIBR',
        'FR_CYL_MOLOT',
        'GAP_UP',
        'GAP_EXT',
        'GAP_DOWN',
        'CURR_NK',
        'LOSS_ROTOR',
        'LOSS_CLEAN',
        'PR_ROTOR',
        'PR_POWER',
        'TMP_GST_MOVE',
        'TMP_GST_ROTOR',
        'FR_MOTOVIL',
        'FR_UP_BITER_NK',
        'FR_ROTOR',
        'FR_VENT',
        'FR_SHAFT',
        'FR_SCREW_GRAIN',
        'FR_SCREW_EAR',
        'FR_BITER_STRAW',
        'FR_CYL_IRS',
        'ELEVATOR_NULL',
        'ELEVATOR_CURR',
        'CURR_PERF_TON',
        'CURR_PRODUCTIVITY',
        'CURR_HYMIDITY',
        'AGGR_ERR',
        'SECTION_STATUS',
        'SPILL_PRESSURE',
        'SPILL_PRESSURE_BAR',
        'TMP_BLOCK',
        'HEIGHT_MODE',
        'ON_VALVE_MAIN',
        'HEIGHT_CENTER',
        'VALVE_MODE',
        'DEVIATION',
        'HEIGHT_RIGTH',
        'SECTIONS_MODE',
        'WHEEL_CONST',
        'HEIGHT_PRESET',
        'OVERLAY_PERC',
        'TMP_TRANSMISSION',
        'TMP_HIDRO',
        'TMP_OPERATOR',
        'ON_BLOCK',
        'ON_HIDRO',
        'CONS_SECT_1',
        'CONS_SECT_2',
        'CONS_SECT_3',
        'CONS_SECT_4',
        'CONS_SECT_5',
        'CONS_SECT_6',
        'WORK_SECT_1',
        'WORK_SECT_2',
        'WORK_SECT_3',
        'WORK_SECT_4',
        'WORK_SECT_5',
        'WORK_SECT_6',
        'LEVEL_CARBAMIDE',
        'SLIP',
        'SUMM_MOLOT_TON',
        'SUMM_MOLOT_CORRECT_TON',
        'SUMM_PERF_TON',
        'SUMM_PRODUCTIVITY',
        'DISP_KNIVES_QUALITY',
        'DISP_COUNT_TIME_SHARPENING',
        'DISP_COUNT_MASS_SHARPENING',
        'COUNT_ST_REG_CYCLES',

        // Conservants
        'LIQUID',
        'VOLUME_CANISTER',
        'SUMM_MEMBRANE_PUMP',
        'SUMM_HOSE_PUMP',
        'SUMM_AVERAGE_MEMBRANE_PUMP_TON',
        'SUMM_AVERAGE_HOSE_PUMP_TON',
        'SUMM_AVERAGE_MEMBRANE_PUMP',
        'SUMM_AVERAGE_HOSE_PUMP',

        // Добавлены параметры для модели RSM 910
        'FAN_PERFORM',
        'PR_GST_MOVE_FORWARD',
        'PR_GST_MOVE_BACKWARD',
        'TMP_HIDRO',
        'FR_UP_BITER_NK',
        'FR_CYL_SEPAR',
        'PR_CYL_MOLOT',
        'STRAW_LOSSES',
        'ROTOR_STRAW_LOSSES',
        'FR_STRAWSEPARATOR',

        'FAN_CONTR_STATUS',
        'REAR_ATTDEV_STATUS',
        'FRONT_ATTDEV_STATUS',
        'PR_BRAKE_LEFT',
        'PR_BRAKE_RIGHT',
        'TMP_REAR_ATTDEV_1',
        'TMP_REAR_ATTDEV_2',
        'TMP_REAR_ATTDEV_3',
        'TMP_REAR_ATTDEV_4',
        'TMP_REAR_ATTDEV_5',
        'TMP_REAR_ATTDEV_6',
        'TMP_FRONT_ATTDEV_1',
        'TMP_FRONT_ATTDEV_2',
        'CONS_FRONT_SECT_1',
        'CONS_FRONT_SECT_2',
        'WORK_FRONT_SECT_1',
        'WORK_FRONT_SECT_2',
        'ON_FRONTAXLE',
        'ON_TURNLANE',
        'PR_PNEVMO',
        'EQUIPMENT',

        //Для отображения в детальной информации данных по датчику СКРП раскомментировать параметр ниже.
        //Сейчас данные с датчика в систему не отправляются, поэтому закомментировано.
        //C_SKRP_STATUS_NAME,
      ],

      isShow,
    },
  ]

  const keys = Object.keys(data)

  return template.map(( { params, isShow, ...others } ) => {
    const sectionParams = pipe(intersection(keys), map(P => ({ ...data[P], key: P })))(params)

    //@ts-expect-error
    const isShowValue = isShow(sectionParams, data)

    return {
      ...others,
      isShow: isShowValue,
      params: sectionParams,
    }
  })
}

export type SensorsData = {
  time?: moment.MomentInput[]
  background?: Record<string, {duration: {beg: moment.MomentInput, end: moment.MomentInput}[]}>
}

export const getSensorsDataPrepareJson = (json: SensorsData): SensorsData => {
  if(json.time) {
    //getSensorsData возвращает данные уже с учётом часового пояса, для Dygraph нужно перевести их в unixtime
    json.time = json.time.map(T => moment(T, 'YYMMDDHHmmss000').unix() * 1000)
  }

  if(json.background) {
    json.background = mapObjIndexed(V => {
      return {
        ...V,

        ...V.duration ? {
          duration: V.duration.map(D => ({
            ...D,

            //getSensorsData возвращает данные уже с учётом часового пояса, для Dygraph нужно перевести их в unixtime
            beg: moment(D.beg, 'YYMMDDHHmmss000').unix() * 1000,
            end: moment(D.end, 'YYMMDDHHmmss000').unix() * 1000,
          })),
        } : {},
      }
    }, json.background)
  }

  return json
}

export const requestIndicators = async (
  data: Required<RequestTrackData>,
  cb: () => void,
  dispatch: AppDispatch,
) => {
  const timezoneOffsetMinutes = new Date().user.gmt * 60

  try {
    const json = await getSensorsData({
      from: data.from,
      imei: data.imei,
      timezoneOffsetMinutes,
      to  : data.to,
    }) as SensorsData

    dispatch(pushIndicatorsReport({ type: data.reportType, data: getSensorsDataPrepareJson(json), imei: data.imei }))

    if(cb) {cb()}
  } catch(err){
    console.error(err)
  }
}

export const requestPredictUnloading = async (imei: number) => {
  const response = await fetch(
    `${urlApi}/unload/unloadpoint/${imei}`,
    { credentials: 'same-origin', method: 'GET' },
  )

  if(response.ok) {
    return await response.json()
  } else {
    console.log(response.status)
  }
}
