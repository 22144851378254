import React, { FC } from 'react'
import { TUnit } from '../../types'
import MarkerState from './markerState'

type MarkerStateWrapperProps = {
  unit: TUnit
  detailPosition?: {
    lat: number
    lon: number
  }
  hasTrack: boolean
  getDataValue: (name: string, target: unknown) => number
}

export const MarkerStateWrapper: FC<MarkerStateWrapperProps> = ({ unit, detailPosition, hasTrack, getDataValue }) => {
  if(!hasTrack && detailPosition) {
    return <MarkerState
      unit={unit}
      position={[detailPosition.lat, detailPosition.lon]}
    />
  }

  if(!hasTrack && getDataValue('LATITUDE', unit) && getDataValue('LONGITUDE', unit)) {
    return <MarkerState
      unit={unit}
      position={[getDataValue('LATITUDE', unit), getDataValue('LONGITUDE', unit)]}
    />
  }

  return null
}
