import { Spinner } from '@agdt/agrotronic-react-components'
import { Button, Tooltip } from 'antd'
import React, { useCallback } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import styled from 'styled-components'
import { MapDetailMachine } from 'ui/Map/mapUnit'
import { TransportPopupUnit } from 'ui/Map/Track/TrackItem/PopupWrap/TransportPopup'
import TooltipD from 'ui/Tooltip'
import { Details } from './Details'
import HOCdetail from './HOCdetail'
import { MainInfo, TDriver, TEquipment } from './MainInfo'
import { TMachine } from './models'
import { type2file, UnitImage } from './UnitImage'

const styles = {
  legend: {
    bottom  : '90px',
    position: 'fixed',
    right   : '20px',
    width   : '400px',
  },

  tooltips: {
    appearance: { bottom: 0, left: 0 },
    equipment : { right: -6, top: 4 },
    model     : { bottom: 22, right: 0 },
  },
}

export type TDetailInformation = {
  id: number
  isShow: boolean
  params: unknown[]
  title: string
}

export type TProps = {
  carrier?: TransportPopupUnit
  demoMode: unknown
  detailInformation: TDetailInformation[] | null
  driver: TDriver | null
  equipment: TEquipment | null
  imei: number
  load: boolean
  machine: TMachine
  detailPosition?: {
    lat: number
    lon: number
  }

  period: [Date, Date]
  reload: (arg: number) => void
}

const MachineDetail = ({
  carrier,
  demoMode,
  detailInformation,
  detailPosition,
  driver,
  equipment,
  imei,
  load,
  machine,
  period,
  reload,
}: TProps) => {
  const t = useTranslate()
  const handleReload = useCallback(() => { reload(imei) }, [imei, reload])

  return <>
    <div className="machine-detail__info">
      <DetailsMain>
        <DetailsMainTop>
          {type2file[machine.typeName] && <UnitImgWrapper>
            <UnitImage typeName={machine.typeName} />

            <TooltipD
              text={t('Appearance vehicles')}
              style={styles.tooltips.appearance}
            />
          </UnitImgWrapper>}

          <NameContainer noImage={!type2file[machine.typeName]}>
            <Model>
              {machine.typeName}

              <TooltipD
                text={t('Make and model of technology')}
                style={styles.tooltips.model}
              />
            </Model>

            <Name>{machine.name}</Name>
            <Name>IMEI {machine.imei}</Name>
          </NameContainer>
        </DetailsMainTop>

        <MainInfo
          driver={driver}
          equipment={equipment}
          load={load}
          machine={machine}
          detailInformation={detailInformation}
          t={t}
        />
      </DetailsMain>

      {!demoMode &&
          <div className={'machine-detail__info-list full'}>
            <div className="detail-blocks">
              <Tooltip title={t('Refresh')} key={1}>
                <Button
                  icon="sync"
                  loading={load}
                  onClick={handleReload}
                  shape="circle"
                  style={{ lineHeight: '10px' }}
                  type="primary"
                />
              </Tooltip>

              <div style={{ fontWeight: 'bold', marginLeft: '15px' }}>{t('period')} {period}</div>
            </div>
          </div>}

      {load && !detailInformation && <SpinnerContainer>
        <Spinner/>
      </SpinnerContainer>}

      {detailInformation && detailInformation.map(item => item.isShow && <Details

        //@ts-expect-error
        item={item}
        key={item.id}
        t={t}
      />)}

      <TooltipD
        text={t('Detailed parameters of the equipment')}
        style={styles.tooltips.equipment}
      />
    </div>

    <MapWrapper>
      <MapDetailMachine

        //@ts-expect-error
        unit={machine}
        transport={carrier}
        trackUpdated={machine.trackUpdated}
        track={
          machine.track &&
            machine.track.activeTrack &&
            machine.track.activeTrack
            ? machine.track.activeTrack.TRACK_HARVEST
            : undefined
        }
        static={
          machine.track && machine.track.static
            ? machine.track.static
            : false
        }
        statusTargets2={machine.data.STATUS.value}
        loadTargets2={machine.data.LOAD}
        activeTargets2={machine.data?.ACTIVE?.time}
        legendStyle={styles.legend as React.CSSProperties}
        detailPosition={detailPosition}
      />
    </MapWrapper>
  </>
}

export default HOCdetail(MachineDetail)

const DetailsMain = styled.div`
  width: 100%;
`

const DetailsMainTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const UnitImgWrapper = styled.div`
  flex: 1 1;
  max-width: 170px;
  min-width: 150px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  & img {
    width: 100%;
  }
`

const Model = styled.div`
  font-size: 22px;
  color: #181818;
`

const Name = styled(Model)`
  font-size: 12px;
  color: #666666;
  font-weight: 400;
`

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const MapWrapper = styled.div`
  width: calc(100% - 370px);
  height: calc(100vh - 60px);

  @media (max-width: 1023px) {
    height: 50vh;
    width: 100%;
  }
`

const NameContainer = styled.div<{ noImage: boolean }>`
  padding-left: ${({ noImage }) => noImage ? '0' : '30px'};
`
