import L, { Marker as LeafletMarker } from 'leaflet'
import { map, pipe, slice } from 'ramda'
import React, { FC, Fragment, ReactElement, useContext,useEffect, useRef } from 'react'
import { LeafletContext, LeafletProvider, withLeaflet } from 'react-leaflet'
import { getCourse } from 'reducers/users/selectors'
import { TUnit } from 'types'
import { ConfigContext } from 'utils'
import { bugIcon } from './markersType'

const getTranslate3d = (el: HTMLElement) => {
  var values = el.style.transform.split(/\w+\(|\);?/)

  if(!values[1] || !values[1].length) {
    return []
  }

  return values[1].split(/,\s?/g)
}

type MarkerStateProps = {
  children?: ReactElement
  unit: TUnit
  position: [number, number]
  leaflet: LeafletContext
  popup?: {
    isOpen: () => boolean
    getElement: () => HTMLElement | undefined
    setLatLng: (value: [number, number]) => void
  }
  status?: number
  getMarker?: (imei: string) => LeafletMarker
  setMarker?: (imei: string, marker: LeafletMarker) => void
  openPopup?: (unit: TUnit, marker: LeafletMarker) => void
}

const MarkerState: FC<MarkerStateProps> = ({
  children,
  unit,
  position,
  leaflet,
  popup,
  status: statusProp,
  getMarker,
  setMarker,
  openPopup,
}) => {
  const context = useContext(ConfigContext)
  const leafletElement = useRef<LeafletMarker | null>(null)
  const fx = useRef<L.PosAnimation | null>(null)

  const prevProps = useRef({
    lat   : unit.data.LATITUDE?.value,
    lng   : unit.data.LONGITUDE?.value,
    position,
    status: unit.data.STATUS.value,
  })

  const layerContainer = leaflet.layerContainer || leaflet.map

  const onOpen = () => {
    if(openPopup && leafletElement.current) {
      const { name, typeName, data, farm } = unit

      openPopup({
        ...unit,
        holding: farm,
        name   : typeName,
        state  : data.STATUS.valueF,
        type   : name,
        watch  : false,
      }, leafletElement.current)
    }
  }

  useEffect(() => {
    const lngCurr = unit.data.LONGITUDE?.value
    const latCurr = unit.data.LATITUDE?.value

    const course = getCourse(lngCurr, prevProps.current.lng, latCurr, prevProps.current.lat)

    // Если не рабочий режим и не дорожный режим рисуем ставим точку без анимации
    if(statusProp !== 1 && statusProp !== 4) {
      // Если анимация была - отменяем
      if(fx.current) {
        fx.current.stop()
        fx.current = null
      }

      if(leafletElement.current) {
        //@ts-expect-error
        leafletElement.current.setIcon(bugIcon(unit.model, unit.data.STATUS.value, course))
      }
    }

    if(prevProps.current.position[0] !== position[0] || prevProps.current.position[1] !== position[1]) {
      //@ts-expect-error
      if(leafletElement.current._icon && !Number.isNaN(position[0]) && !Number.isNaN(position[1])) {
        fx.current = new L.PosAnimation()

        const positionPopup = (x: number, y: number, popupEl: HTMLElement) => {
          if(popupEl?.style) {
            popupEl.style.transform = `translate3d(
              ${x - popupEl.clientWidth / 2 - parseFloat(popupEl.style.left)}px,
              ${y + 10 + parseFloat(popupEl.style.bottom)}px,
              0px)
            `
          }
        }

        // If popup opened move it with marker too
        fx.current.on('step', () => {
          if(popup && popup.isOpen()) {
            const popupEl = popup.getElement()

            if(popupEl){
              //@ts-expect-error
              positionPopup(...[...pipe(slice(0, 2), map(C => parseFloat(C)))(getTranslate3d(popupEl)), popupEl])
            }
          }
        })

        // Finalize marker & popup movement, apply setLatLng except style.transform offset
        fx.current.on('end', () => {
          if(leafletElement.current) {
            leafletElement.current.setLatLng(position)
          }

          if(popup) {
            const popupEl = popup.getElement()

            if(popupEl) {
              popupEl.style.transform = ''
            }

            popup.setLatLng(position)
          }
        })

        if(leafletElement.current) {
          fx.current.run(

            //@ts-expect-error
            leafletElement.current._icon,

            //@ts-expect-error
            leafletElement.current._map.latLngToLayerPoint(position),
            50,
          )


          //@ts-expect-error
          leafletElement.current._map.once('viewreset', () => fx.current && fx.current.stop())
        }
      } else {
        if(leafletElement.current) {
          leafletElement.current.setLatLng(position)
        }

        if(popup) {
          popup.setLatLng(position)
        }
      }
    }

    //При изменении статуса - менять иконку
    if(prevProps.current.status !== unit.data.STATUS.value) {
      if(leafletElement.current) {
        //@ts-expect-error
        leafletElement.current.setIcon(bugIcon(unit.model, unit.data.STATUS.value, course))
      }
    }

    prevProps.current = {
      lat   : unit.data.LATITUDE?.value,
      lng   : unit.data.LONGITUDE?.value,
      position,
      status: unit.data.STATUS.value,
    }
  })

  useEffect(() => {
    const { data, model, course, imei } = unit

    // Ability to use cache or already created markers object, need to be care for avoid memory leaks on not unbinded handlers
    if(getMarker) {
      leafletElement.current = getMarker(imei)
    }

    if(!leafletElement.current) {
      leafletElement.current = new LeafletMarker(position, {

        //@ts-expect-error
        icon: bugIcon(model, data.STATUS.value, course),
      })

      // Save marker to cache, TODO: unbind handlers
      if(setMarker) {
        setMarker(imei, leafletElement.current)
      }
    }

    //@ts-expect-error
    const addLayer = (layerContainer?.addLayerDelayed || layerContainer?.addLayer)
      .bind(layerContainer)

    addLayer(leafletElement.current)

    if(openPopup) {
      // Element probably used before in other react marker, unbind for be sure
      leafletElement.current.off('click')
      leafletElement.current.on('click', onOpen)
    }

    return () => {
      if(popup){
        //@ts-expect-error
        layerContainer.closePopup()
      }

      //@ts-expect-error
      const removeLayer = (layerContainer?.removeLayerDelayed || layerContainer?.removeLayer)
        .bind(layerContainer)

      removeLayer(leafletElement.current)
    }
  }, [])

  if(!children) {
    return null
  }

  if(context) {
    //@ts-expect-error
    return <LeafletProvider value={context}>{children}</LeafletProvider>
  }

  return (
    <Fragment>{children}</Fragment>
  )
}

export default withLeaflet(MarkerState)
