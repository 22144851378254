import L, { Layer } from 'leaflet'
import React, { FC, useEffect, useRef, useState } from 'react'
import { Map, Marker, Polyline } from 'react-leaflet'
import { TTranslate } from 'types'
import { bugIcon, iconFinish } from '../../../markersType'
import { PopupWrap } from '../PopupWrap'
import { TransportPopupUnit } from '../PopupWrap/TransportPopup'

export type Point = {
  lat: number
  lng: number
  status: number
  t: string
}

type PolylineWrapProps = {
  isWatched: boolean
  last: boolean
  animate?: boolean
  endDateIsToday: boolean
  static?: boolean
  statusTargets2?: number
  model?: string
  finish?: Point
  path: Point[]
  rotate?: number
  transport?: TransportPopupUnit
  measureModel: string
  activeTargets2?: number
  name: string
  manipulation: boolean
  imei: number
  polylineKey: string
  productivity: boolean
  color: string
  dashed?: boolean
  weight: number
  map: Map
  animateDuration?: number
  arrow: boolean
  deleteTrack: (value: {imei: number}) => void
  translate: TTranslate
}

export const PolylineWrap: FC<PolylineWrapProps> = ({
  last,
  animate,
  finish,
  path,
  isWatched,
  endDateIsToday,
  static: staticProp,
  statusTargets2,
  model,
  rotate,
  transport,
  measureModel,
  activeTargets2,
  name,
  manipulation,
  imei,
  polylineKey,
  productivity,
  color,
  dashed,
  weight,
  map,
  animateDuration,
  arrow,
  deleteTrack,
  translate,
}) => {
  const getPositionMarker = (): [number, number] | null => {
    if(finish && !animate) {
      return [finish.lat, finish.lng]
    }

    if(animate) {
      return [path[0].lat, path[0].lng]
    }

    return null
  }

  const [positionMarker] = useState<[number, number] | null>(getPositionMarker())
  const markerRef = useRef<Marker>(null)
  const [point, setPoint] = useState<Point | null>(null)
  const activePolyline = useRef<L.LayerGroup | null>(null)
  const status = finish?.status || statusTargets2 || 0
  const line = useRef<L.Polyline | null>(null)
  const [arrowState, setArrow] = useState<Layer| null>(null)
  const prevProps = useRef({ arrow, pathLength: path.length })

  const time = activeTargets2 && !staticProp && !finish
    ? Date.parseDateTimeFromApi(String(activeTargets2)).user.format('DD.MM.YY HH:mm:ss')
    : finish ? Date.parseDateTimeFromApi(finish.t).user.format('DD.MM.YY HH:mm:ss') : '-'

  const getPoint = (event: {latlng: [number, number]}) =>{
    const getDistanceTo = (item: Point) => L.latLng({
      lat: item.lat,
      lng: item.lng,
    }).distanceTo(event.latlng)

    setPoint(path.reduce((acc, item) => getDistanceTo(acc) < getDistanceTo(item) ? acc : item, path[0]))
  }

  const setSpeedPolyline = (finishPolyline: Point[]) => {
    const newPointStart = map.leafletElement.latLngToContainerPoint(finishPolyline[0])

    const newPointEnd = map.leafletElement.latLngToContainerPoint(finishPolyline[1])

    if(
      newPointStart &&
      newPointEnd &&
      !(newPointStart.x === newPointEnd.x && newPointStart.y === newPointEnd.y)
    ) {
      const newDistancePx = L.point(newPointStart).distanceTo(newPointEnd)

      if(line.current) {
        line.current.setStyle({
          lineCap : 'butt',
          lineJoin: 'round',
          opacity : productivity ? 0.7 : 1,

          //@ts-expect-error
          snakingSpeed: newDistancePx / (animateDuration / 1000),
          weight,
        })
      }
    }
  }

  const animatePolyline = () => {
    const finishPolyline = path.filter((_item, index) => index === path.length - 1 || index === path.length - 2)

    if(!animate) {
      return
    }

    if(finishPolyline[0].lat !== finishPolyline[1].lat || finishPolyline[0].lng !== finishPolyline[1].lng) {
      const pointStart = map.leafletElement.latLngToContainerPoint(
        finishPolyline[1],
      )

      const pointEnd = map.leafletElement.latLngToContainerPoint(
        finishPolyline[0],
      )

      if(pointStart && pointEnd && !(pointStart.x === pointEnd.x && pointStart.y === pointEnd.y)) {
        const distancePx = L.point(pointStart).distanceTo(pointEnd)

        line.current = L.polyline(finishPolyline, {
          color,
          lineCap : 'butt',
          lineJoin: 'round',
          opacity : productivity ? 0.7 : 1,

          //@ts-expect-error
          snakingSpeed: distancePx / (animateDuration / 1000),
          weight,
        })

        //@ts-expect-error
        line.current.on('click', getPoint)

        if(activePolyline.current) {
          //@ts-expect-error
          line.current.addTo(activePolyline.current).snakeIn()
        }


        if(markerRef.current) {
          //@ts-expect-error
          markerRef.current.leafletElement.slideTo(finishPolyline[1], {
            duration: animateDuration,
          })
        }

        map.leafletElement.on('zoomend', () => {
          setSpeedPolyline(finishPolyline)
        },
        )
      }
    }
  }

  const addArrow = () => {
    const listPath = path.reduce((result: [number, number][], item) => {
      if(item.lat && item.lng) {
        result.push([item.lat, item.lng])
      }

      return result
    }, [])

    //@ts-expect-error
    const layer = L.polylineDecorator(listPath, {
      patterns: [
        {
          offset: 0,
          repeat: 40,

          //@ts-expect-error
          symbol: L.Symbol.arrowHead({
            pathOptions: {
              color  : color,
              opacity: 1,
              stroke : true,
              weight : 1,
            },
            pixelSize: 7,
            polygon  : false,
          }),
        },
      ],
    })

    setArrow(layer)
    activePolyline.current?.addLayer(layer)
  }

  useEffect(()=>{
    activePolyline.current = L.layerGroup().addTo(map.leafletElement)

    if(animate && last) {
      animatePolyline()
    }

    if(arrow) {
      addArrow()
    }

    return () => {
      if(activePolyline.current) {
        activePolyline.current.clearLayers()
      }
    }
  }, [])

  useEffect(() => {
    return () => {
      if(arrowState) {
        map.leafletElement.removeLayer(arrowState)
      }
    }
  }, [arrowState])

  useEffect(()=>{
    if(prevProps.current.arrow !== arrow) {
      if(arrow) {
        addArrow()
      } else {
        if(arrowState) {
          map.leafletElement.removeLayer(arrowState)
        }
      }
    }

    if(prevProps.current.pathLength !== path.length && arrow) {
      addArrow()
    }

    if(animate && last) {
      animatePolyline()
    }

    if(transport && markerRef.current && markerRef.current.leafletElement) {
      markerRef.current.leafletElement.openPopup()
    }

    prevProps.current = {
      arrow,
      pathLength: path.length,
    }
  })

  return (
    <div>
      {last && positionMarker &&
        <Marker
          ref={markerRef}
          key={'track-finish-icon'}
          position={positionMarker}
          icon={
            (staticProp || animate || isWatched && endDateIsToday) && model
              ? bugIcon(model, status, rotate, transport)
              : iconFinish
          }
          zIndexOffset={10}
        >
          {finish && <PopupWrap
            {...finish}
            measure={measureModel === 'ZUK' ? 'cwt/ha' : 't/ha'}
            time={time}
            status={status}
            name={name}
            manipulation={manipulation}
            deleteTrack={deleteTrack}
            translate={translate}
            transport={transport}
            imei={imei}
          />
          }
        </Marker>
      }

      {point &&
        <PopupWrap
          {...point}
          time={Date.parseDateTimeFromApi(point.t).format('DD.MM.YY HH:mm:ss')}
          name={name}
          manipulation={manipulation}
          deleteTrack={deleteTrack}
          translate={translate}
          measure={measureModel === 'ZUK' ? 'cwt/ha' : 't/ha'}
          imei={imei}
        />
      }

      {!animate && <Polyline
        onClick={getPoint}
        key={polylineKey}
        positions={!productivity ? path : path.filter(item => item.status === 4)}
        color={color}
        weight={weight}
        opacity={productivity ? 0.7 : 1}
        lineCap="butt"
        lineJoin="round"
        dashArray={dashed ? '5 5' : undefined}
      />}
    </div>
  )
}
